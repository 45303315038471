import {presentYear} from 'helpers/time';
import validate from 'validate.js';
import {validateDatetime} from 'helpers/time';

validate.extend(validate.validators.datetime, validateDatetime);

import {userErrorMessagesTranslations} from 'User/helpers/translations';

const {
  requiredValue,
  invalidEmailAddress,
  useLatinCharacters,
  useAlphanumericCharacters,
  onlyLatinNoSpace,
  atLeastChars,
  atLeastCharsVehicle,
  selectGender,
  invalidDate,
  invalidCreditCardNumber,
  invalidCvv
} = userErrorMessagesTranslations;

const calculateScore = (password) => {
  let score = 0;

  // password length
  score += password.length * 4;
  score += checkRepetition(1, password).length - password.length;
  score += checkRepetition(2, password).length - password.length;
  score += checkRepetition(3, password).length - password.length;
  score += checkRepetition(4, password).length - password.length;

  // password has 3 numbers
  if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
    score += 5;
  }

  // password has at least 2 symbols
  let symbols = '.*[!,@,#,$,%,^,&,*,?,_,~]';
  symbols = new RegExp('(' + symbols + symbols + ')');
  if (password.match(symbols)) {
    score += 5;
  }

  // password has Upper and Lower chars
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    score += 10;
  }

  // password has number and chars
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    score += 15;
  }

  // password has number and symbol
  if (password.match(/([!@#$%^&*?_~])/) && password.match(/([0-9])/)) {
    score += 15;
  }

  // password has char and symbol
  if (password.match(/([!@#$%^&*?_~])/) && password.match(/([a-zA-Z])/)) {
    score += 15;
  }

  // password is just numbers or chars
  if (password.match(/^\w+$/) || password.match(/^\d+$/)) {
    score -= 10;
  }

  if (score > 100) {
    score = 100;
  }

  if (score < 0) {
    score = 0;
  }

  return score;
};

const checkRepetition = (length, str) => {
  let res = '', i, j, repeated = false;
  for (i = 0; i < str.length; i++) {
    repeated = true;
    for (j = 0; j < length && (j + i + length) < str.length; j++) {
      repeated = repeated && (str.charAt(j + i) === str.charAt(j + i + length));
    }
    if (j < length) {
      repeated = false;
    }
    if (repeated) {
      i += length - 1;
      repeated = false;
    }
    else {
      res += str.charAt(i);
    }
  }
  return res;
};

const testPassword = (value) => ({
  score: calculateScore(value),
  lowerCase: /(?=.*[a-z])/.test(value),
  upperCase: /(?=.*[A-Z])/.test(value),
  numbers: /(?=(.*[0-9]){2,})/.test(value),
  symbol: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
  length: value.length > 7 && value.length < 17
});

const notEmpty = (message, allowEmpty = false) => ({
  allowEmpty: false || allowEmpty,
  message: `^${message}`
});

const englishAlphabetWhiteSpace = {
  pattern: '[a-z ]+',
  flags: 'i',
  message: `^${useLatinCharacters}`
};

const onlyDigits = (message) => ({
  pattern: '[0-9]+',
  flags: 'i',
  message: `^${message}`
});

const notEmptyAndLatin = {
  presence: notEmpty(requiredValue),
  format: englishAlphabetWhiteSpace
};

const englishAlphabetNumbersWhiteSpace = (message) => ({
  pattern: '[a-z 0-9]+',
  flags: 'i',
  message: `^${message}`
});

const englishAlphabetNumbersWhiteSpaceCommaDashFullstop = (message) => ({
  pattern: '[a-z 0-9,-.]+',
  flags: 'i',
  message: `^${message}`
});

const notEmptyAndLatinNumbersWhiteSpace = {
  presence: notEmpty(requiredValue),
  format: englishAlphabetNumbersWhiteSpace(useAlphanumericCharacters)
};

const notEmptyAndLatinNumbersWhiteSpaceCommaDashFullstop = {
  presence: notEmpty(requiredValue),
  format: englishAlphabetNumbersWhiteSpaceCommaDashFullstop(useAlphanumericCharacters)
};

const mandatory = {
  presence: notEmpty(requiredValue)
};

const email = {
  presence: notEmpty(requiredValue),
  email: {
    message: `^${invalidEmailAddress}`
  }
};

const digits = (message) => ({
  presence: notEmpty(requiredValue),
  format: onlyDigits(message)
});

const ccNumberFormat = (message) => ({
  pattern: '^[0-9]{11,19}$',
  flags: 'i',
  message: `^${message}`
});

const ccvFormat = (message) => ({
  pattern: '^[0-9]{3,4}$',
  flags: 'i',
  message: `^${message}`
});

const minPlateNumber = 4;
const plateNumber = {
  presence: notEmpty(requiredValue),
  format: {
    pattern: '[a-z0-9]+',
    flags: 'i',
    message: `^${onlyLatinNoSpace}`
  },
  length: {
    minimum: minPlateNumber,
    message: `^${atLeastCharsVehicle.replace('{number}', minPlateNumber)}`
  }
};

const minPasswordLength = 8;
const password = {
  presence: notEmpty(requiredValue),
  length: {
    minimum: minPasswordLength,
    message: `^${atLeastChars.replace('{number}', minPasswordLength)}`
  }
};

const minFullnameLength = 2;
const fullname = {
  ...notEmptyAndLatin,
  length: {
    minimum: minFullnameLength,
    message: `^${atLeastChars.replace('{number}', minFullnameLength)}`
  }
};

const gender = {
  presence: notEmpty(selectGender)
};

const ccNumber = {
  presence: notEmpty(requiredValue),
  format: ccNumberFormat(invalidCreditCardNumber)
};

const cvv = {
  presence: notEmpty(requiredValue),
  format: ccvFormat(invalidCvv)
};

const ccMonth = {
  presence: notEmpty(requiredValue),
  length: {
    is: 2,
    message: `^${invalidDate}`
  },
  numericality: {
    onlyInteger: true,
    greaterThan: 0,
    lessThan: 13,
    message: `^${invalidDate}`
  }
};

const ccYear = {
  presence: notEmpty(requiredValue),
  length: {
    is: 2,
    message: `^${invalidDate}`
  },
  numericality: {
    onlyInteger: true,
    greaterThanOrEqualTo: parseInt(presentYear, 10),
    message: `^${invalidDate}`
  }
};

const dateBounds = (min, max) => ({
  presence: notEmpty(requiredValue),
  datetime: {
    latest: max,
    earliest: min,
    message: `^${invalidDate}`
  }
});

const bookingCode = {
  ...notEmptyAndLatinNumbersWhiteSpace
};

export {
  mandatory,
  email,
  fullname,
  password,
  gender,
  testPassword,
  digits,
  plateNumber,
  ccNumber,
  cvv,
  ccMonth,
  ccYear,
  notEmptyAndLatinNumbersWhiteSpace,
  notEmptyAndLatinNumbersWhiteSpaceCommaDashFullstop,
  dateBounds,
  bookingCode
};
